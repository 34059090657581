import { useCallback, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { getCurrencySymbol } from "../../utils/currencies";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { extractEndpoint, getPageNumBasedOnUrl } from "../../utils/pagination";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ReceivedFilter from "./ReceivedFilter";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaEdit, FaMailBulk } from "react-icons/fa";
import InvoiceEdit from "./InvoiceEdit";
import { useToast } from "../../hooks/useToast";
import { InvoiceModel } from "../../types/invoices";
import { DEFAULT_CURRENCY } from "../../consts/currencies";
import InvoiceMailing from "./InvoiceMailing";
import ExportInvoices from "./ExportInvoices";
import { DatePicker } from "@mui/x-date-pickers";
import InvoicingForm from "./InvoicingForm";

const InvoicingContainer = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [invoicingSettingsModalOpen, setInvoicingSettingsModalOpen] =
    useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel | null>(
    null
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [invoiceMailModalOpen, setInvoiceMailModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [totalInvoices, setTotalInvoices] = useState<number>(0);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [warnings, setWarnings] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [invoicesPastDueAmount, setInvoicesPastDueAmount] = useState<number>(0);
  const [invoicesDueNextWeekAmount, setInvoicesDueNextWeekAmount] =
    useState<number>(0);
  const [invoicesDueNextMonthAmount, setInvoicesDueNextMonthAmount] =
    useState<number>(0);
  const [invoicesNotPaidTotalAmount, setInvoicesNotPaidTotalAmount] =
    useState<number>(0);
  const [notInvoicedOrders, setNotInvoicedOrders] = useState<number>(0);

  const INVOICES_PER_PAGE = 20;

  const [selectedReceivedStatus, setSelectedReceivedStatus] = useState({
    received: false,
    notReceived: false,
    all: true,
  });
  const axios = useAxios();
  const toast = useToast();

  const fetchInvoices = async (url: string) => {
    setLoading(true);
    try {
      const { data } = await axios.get(url);
      setInvoices(data.results);
      setTotalInvoices(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(getPageNumBasedOnUrl(url));
      setInvoicesPastDueAmount(data.invoices_past_due_amount);
      setInvoicesDueNextWeekAmount(data.invoices_due_next_week_amount);
      setInvoicesDueNextMonthAmount(data.invoices_due_next_month_amount);
      setInvoicesNotPaidTotalAmount(data.invoices_not_paid_total_amount);
      setWarnings(data.warnings);
      setNotInvoicedOrders(data.not_invoiced_orders);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchInvoices = useCallback(
    debounce((url) => fetchInvoices(url), 500),
    []
  );

  const getReceivedStatus = () => {
    return selectedReceivedStatus.all
      ? ""
      : selectedReceivedStatus.received
      ? "received"
      : "not_received";
  };

  const buildUrl = () => {
    const receivedStatus = getReceivedStatus();
    return `/invoices?received_status=${receivedStatus}&search=${searchQuery}`;
  };

  useEffect(() => {
    const initialUrl = buildUrl();
    debouncedFetchInvoices(initialUrl);
  }, [searchQuery, selectedReceivedStatus]);

  const fetchInvoicesByPage = async (page: number) => {
    try {
      const receivedStatus = getReceivedStatus();
      const url = `/invoices?page=${page}&received_status=${receivedStatus}&search=${searchQuery}`;
      const { data } = await axios.get(url);
      setInvoices(data.results);
      setTotalInvoices(data.count);
      setNextUrl(extractEndpoint(data.next));
      setPrevUrl(extractEndpoint(data.previous));
      setCurrentPage(page);
      setInvoicesPastDueAmount(data.invoices_past_due_amount);
      setInvoicesDueNextWeekAmount(data.invoices_due_next_week_amount);
      setInvoicesDueNextMonthAmount(data.invoices_due_next_month_amount);
      setInvoicesNotPaidTotalAmount(data.invoices_not_paid_total_amount);
      setWarnings(data.warnings);
      setNotInvoicedOrders(data.not_invoiced_orders);
    } finally {
      setLoading(false);
    }
  };

  const onSetDeliveryDate = (invoiceId: number, deliveryDate: Date) => {
    const utcDate = new Date(Date.UTC(
      deliveryDate.getFullYear(),
      deliveryDate.getMonth(),
      deliveryDate.getDate()
    ));
    axios
      .patch(`/invoices/${invoiceId}/`, {
        shipper_received_at: utcDate.toISOString(),
      })
      .then(() => {
        setInvoices(
          invoices.map((invoice) => {
            if (invoice.id === invoiceId) {
              return {
                ...invoice,
                shipper_received_at: utcDate.toISOString(),
              };
            }

            return invoice;
          })
        );
      });
  };

  const renderSmartDots = () => {
    const totalPages = Math.ceil(totalInvoices / INVOICES_PER_PAGE);
    const pages: any[] = [];
    pages.push(1);

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    if (startPage > 2) {
      pages.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages.map((page, index) => {
      if (page === "...") {
        return (
          <span key={index} className="px-2 py-1 text-black">
            ...
          </span>
        );
      } else {
        return (
          <button
            key={index}
            onClick={() => fetchInvoicesByPage(page as number)}
            className={`mx-1 px-2 py-1 rounded ${
              page === currentPage
                ? "bg-accent text-white"
                : "bg-gray-300 text-black"
            }`}
            aria-label={`Go to page ${page}`}
          >
            {page}
          </button>
        );
      }
    });
  };

  const onMarkPaid = (invoiceId: number) => {
    axios
      .patch(`/invoices/${invoiceId}/`, {
        is_paid: true,
      })
      .then((response) => {
        setInvoices(
          invoices.map((invoice) => {
            if (invoice.id === invoiceId) {
              return response.data;
            }
            return invoice;
          })
        );
      });
  };

  const onUpdateInvoice = (invoiceId: number, data: any) => {
    axios.patch(`/invoices/${invoiceId}/`, data).then((response) => {
      toast({
        message: t("invoices.invoiceActionModal.success"),
        type: "success",
      });
      setInvoices(
        invoices.map((invoice) => {
          if (invoice.id === invoiceId) {
            return response.data;
          }
          return invoice;
        })
      );
    });
  };

  const calculateDaysRemaining = (invoice: InvoiceModel) => {
    if (!invoice.shipper_received_at) {
      return null;
    }
    if (invoice.is_paid) {
      return null;
    }
    const currentDate = new Date();
    const receivedDate = new Date(invoice.shipper_received_at);
    const paymentDays = invoice.due_days;

    const daysRemaining = Math.floor(
      (receivedDate.getTime() +
        paymentDays * 24 * 60 * 60 * 1000 -
        currentDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    return daysRemaining;
  };

  const handleSearchQueryChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="p-4 ">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Typography color="text.primary">{t("invoices.title")}</Typography>
      </Breadcrumbs>

      <div className="w-full px-4 py-4 flex space-y-4 items-start justify-between">
        <div className="flex justify-between items-start flex-col">
          <div className="flex flex-col gap-2 text-x">
            {warnings.map((warning, index) => (
              <p key={index} className="text-red-500">
                {warning}
              </p>
            ))}
          </div>
          <div>
            {" "}
            {invoicesPastDueAmount > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-red-600">
                {t("invoices.pastDueAmount")}: {""}
                {invoicesPastDueAmount}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
          <div>
            {invoicesDueNextWeekAmount > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-yellow-500">
                {t("invoices.invoicesDueNextWeek")}: {""}
                {invoicesDueNextWeekAmount}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
          <div>
            {invoicesDueNextMonthAmount > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-blue-500">
                {t("invoices.invoicesDueNextMonth")}: {""}
                {invoicesDueNextMonthAmount}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
          <div>
            {invoicesNotPaidTotalAmount > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-red-500">
                {t("invoices.invoicesNotPaidTotal")}: {""}
                {invoicesNotPaidTotalAmount}
                {getCurrencySymbol(DEFAULT_CURRENCY)}
              </h2>
            )}
          </div>
          <div>
            {notInvoicedOrders > 0 && (
              <h2 className="text-2xl font-bold dark:text-secondary text-red-500">
                {t("invoices.notInvoicedOrders")}: {""}
                {notInvoicedOrders}
              </h2>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end gap-4">
          <Button
            variant="contained"
            onClick={() => setInvoicingSettingsModalOpen(true)}
          >
            {t("invoices.settings")}
          </Button>
          <ExportInvoices />
        </div>
      </div>

      <div className="flex flex-row-reverse gap-4 w-full p-8">
        <TextField
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder={t("invoices.searchPlaceholder")}
        />
        <ReceivedFilter
          onChange={setSelectedReceivedStatus}
          selectedReceivedStatus={selectedReceivedStatus}
        />
      </div>
      <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full my-2">
        <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
          <tr>
            <th className="px-4 py-2 text-left">Nazwa</th>
            <th className="px-4 py-2 text-left">Numer</th>
            <th className="px-4 py-2 text-left">Termin płatności</th>
            <th className="px-4 py-2 text-left">Dostarczono</th>
            <th className="px-4 py-2 text-left">Kwota</th>
            <th className="px-4 py-2 text-left">Dane kontaktowe</th>
            <th className="px-4 py-2 text-left">Akcje</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan={7} className="text-center py-4">
                <CircularProgress />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-gray-600 dark:text-white">
            {invoices.map((invoice) => {
              const daysRemaining = calculateDaysRemaining(invoice);
              return (
                <tr
                  key={invoice.id}
                  className="border-b hover:bg-gray-100 transition duration-150 ease-in-out"
                >
                  <td className="px-4 py-3 text-left text-xs max-w-xs overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                    {invoice.recipient?.name}
                  </td>
                  <td className="px-4 py-3 text-left">{invoice.number}</td>
                  <td
                    className="py-3 text-left"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html={`<div>Faktura z dnia ${invoice.issue_date}<br/> <br/>Termin ${invoice.due_days} dni</div>`}
                  >
                    {daysRemaining === null && (
                      <>
                        {invoice.is_paid ? (
                          <span className="text-green-500">
                            {t("invoices.paid")}
                          </span>
                        ) : (
                          <span className="text-gray-500">
                            {invoice.due_date}
                          </span>
                        )}
                      </>
                    )}
                    {daysRemaining !== null && daysRemaining < 0 ? (
                      <>
                        <span className="text-red-500">
                          Przeterminowana o {Math.abs(daysRemaining)} dni
                        </span>
                        <IconButton onClick={() => onMarkPaid(invoice.id)}>
                          <IoMdCheckmarkCircleOutline className="text-accent w-4 h-4" />
                        </IconButton>
                      </>
                    ) : daysRemaining !== null ? (
                      <>
                        <span className="text-green-500">
                          Pozostało {daysRemaining} dni
                        </span>
                        <IconButton onClick={() => onMarkPaid(invoice.id)}>
                          <IoMdCheckmarkCircleOutline className="text-accent w-4 h-4" />
                        </IconButton>
                      </>
                    ) : (
                      ""
                    )}

                    <ReactTooltip id="my-tooltip" place="top" />
                  </td>

                  <td>
                    <DatePicker
                      value={invoice.shipper_received_at ? new Date(invoice.shipper_received_at) : null}
                      format="dd/MM/yyyy"
                      onChange={(date) => {
                        if (date) {
                          onSetDeliveryDate(invoice.id, date);
                        }
                      }}
                    />
                  </td>
                  <td className="px-4 py-3 text-left flex flex-col items-start">
                    <p>
                      {invoice.prices.map((price) => {
                        return (
                          <div key={price.currency}>
                            {price.gross_value}
                            {getCurrencySymbol(price.currency)}
                          </div>
                        );
                      })}
                    </p>
                  </td>
                  <td className="px-4 py-3  text-left">
                    <p>
                      {invoice.contact_email} - {invoice.contact_phone}
                    </p>
                  </td>
                  <td className="px-4 py-3 text-left">
                    <Link to={`/orders/${invoice.order_id}?tab=payment`}>
                      <FaEye />
                    </Link>
                    <IconButton
                      onClick={() => {
                        setModalOpen(true);
                        setSelectedInvoice(invoice);
                      }}
                    >
                      <FaEdit className="text-accent" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setInvoiceMailModalOpen(true);
                        setSelectedInvoice(invoice);
                      }}
                    >
                      <FaMailBulk className="text-accent" />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div className="text-center my-2">
        <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full font-semibold tracking-wide">
          Total Invoices: {totalInvoices}
        </span>
      </div>
      <div className="flex justify-center items-center gap-1 my-2">
        {renderSmartDots()}
      </div>
      <div className="flex justify-center gap-4">
        <Button
          variant="contained"
          color="info"
          onClick={() => prevUrl && fetchInvoices(prevUrl)}
          disabled={!prevUrl}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Prev
        </Button>
        <Button
          variant="contained"
          onClick={() => nextUrl && fetchInvoices(nextUrl)}
          disabled={!nextUrl}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Next
        </Button>
      </div>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{t("invoices.invoiceActionModal.title")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          {selectedInvoice && (
            <InvoiceEdit
              invoice={selectedInvoice}
              onSubmit={(data) => onUpdateInvoice(selectedInvoice.id, data)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={invoiceMailModalOpen}
        onClose={() => setInvoiceMailModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("invoices.invoiceMailModal.title")}</DialogTitle>
        <DialogContent>
          {selectedInvoice && <InvoiceMailing invoice={selectedInvoice} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setInvoiceMailModalOpen(false)}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={invoicingSettingsModalOpen}
        onClose={() => setInvoicingSettingsModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("invoices.invoicingSettingsModal.title")}</DialogTitle>
        <DialogContent>
          <InvoicingForm />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setInvoicingSettingsModalOpen(false)}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvoicingContainer;
