export const COUNTRIES = [
  "pl",
  "de",
  "fr",
  "it",
  "es",
  "nl",
  "be",
  "lu",
  "cz",
  "gb",
  "at",
  "pt",
  "ch",
  "lt",
  "sk",
  "se",
  "lv",
  "ee",
  "dk",
  "hr",
  "fi",
  "ie",
  "is",
  "no",
  "si",
  "mt",
  "cy",
  "li",
  "mk",
  "ro",
  "sm",
  "rs",
  "al",
  "md",
  "ad",
  "gr",
  "tr",
  "xk",
  "me",
  "hu",
];

export const VAT_RATES = {
  pl: 23,
  de: 19,
  fr: 20,
  it: 22,
  es: 21,
  nl: 21,
  be: 21,
  lu: 17,
  cz: 21,
  at: 20,
  pt: 23,
  ch: 0,
  lt: 21,
  sk: 20,
  se: 25,
  gb: 20,
  lv: 21,
  ee: 20,
  dk: 25,
  hr: 25,
  hu: 27,
};

export const DEFAULT_COUNTRY = "pl";
